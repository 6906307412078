import axios from "axios";
import keys from "../../alert";

export default {
  //******************************************//
  //   GET ALL RECORDS FROM VERSIONLOGS API   //
  //******************************************//

  Get() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/versionLogs`, keys.auth.apiKey)
    );
  },

  //******************************************//
  //   INSERT RECORDS FROM VERSIONLOGS API    //
  //******************************************//

  Post(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/versionLogs`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //******************************************//
  //   UPDATE RECORDS FROM VERSIONLOGS API    //
  //******************************************//

  Update(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/versionLogs`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //******************************************//
  //           UPLOAD IMAGE API               //
  //******************************************//

  UploadImage(files) {
    return axios.post(
      `${keys.auth.BaseUrl}/UploadImages`,
      files,
      keys.auth.apiKey
    );
  },
};
